import React from "react";
import type { DifferenceByWords } from "../types";
import classNames from "classnames";

import "./DiffContentView.css";

type DiffContentViewProps = {
  content: DifferenceByWords;
};

const DiffEntry = (props) => {
  const { index, entry } = props;
  const parts = entry.value.split("\n\n");

  if (parts.length > 1) {
    return (
      <>
        {parts.map((part, pindex) => (
          <>
            <span
              key={index + "-" + pindex}
              className={classNames({
                added: entry.added,
                removed: entry.removed,
              })}
            >
              {part}
            </span>
            {pindex < parts.length - 1 && (
              <>
                <br key={pindex + 'b1'} />
                <br key={pindex + 'b2'} />
              </>
            )}
          </>
        ))}
      </>
    );
  }

  return (
    <span
      key={index}
      className={classNames({
        added: entry.added,
        removed: entry.removed,
      })}
    >
      {entry.value}
    </span>
  );
};

export const DiffContentView = (props: DiffContentViewProps) => {
  const { content } = props;
  return (
    <article
      className="DiffContentView"
      style={{ fontFamily: "serif", marginTop: 0 }}
    >
      {content.diff.map((diffs, dIndex) =>
        Array.isArray(diffs) ? (
          <p key={dIndex}>
            {diffs.map((diffEntry, deIndex) => (
              <span
                key={deIndex}
                className={classNames({
                  added: diffEntry.added,
                  removed: diffEntry.removed,
                })}
              >
                {diffEntry.value}
              </span>
            ))}
          </p>
        ) : (
          <DiffEntry index={dIndex} entry={diffs} />
        )
      )}
    </article>
  );
};
